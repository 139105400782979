import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    async ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      );
      const cacheName = 'app-cache';

      // Récupère toutes les images présentes sur le site
      const images = document.querySelectorAll('img');
      const urlsToCache = Array.from(images).map(img => img.src);

      const cache = await caches.open(cacheName);
      await cache.addAll(urlsToCache);

      self.addEventListener('push', (event: any) => {
        const data = event.data.json();
        (self as any).registration.showNotification(data.title, {
          body: data.body,
          icon: data.icon,
          data: {
            url: data.url
          }
        });
      });

      self.addEventListener('notificationclick', (event: any) => {
        const notification = event.notification;
        const action = event.action;

        if (action === 'close') {
          notification.close();
        } else {
          const clients = (self as any).clients;
          clients.openWindow(notification.data.url);
          notification.close();
        }
      });
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated() {
      console.log('New content is available; please refresh.');
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error: any) {
      console.error('Error during service worker registration:', error);
    }
  });
}
