<template>
    <div>
        <div v-if="errorMessage" class="bg-white my-[10px] text-red-600 text-center font-bold flex justify-center items-center fixed left-[50%] translate-x-[-50%] z-30 rounded-[5px] max-w-[550px] w-[90%] p-[20px] ">{{ errorMessage }}</div>
        <transition>
            <div v-if="showIntro" class="intro-container">
                <img src="../assets/images/fond.png" alt="" class="w-full h-full absolute object-cover opacity-20">
                <div class="intro-content">
                    <h1 class="intro-title">
                        <img src="../assets/images/Logo.jpg" alt="" class="w-[50px] h-[50px] md:w-[75px] md:h-[75px] rounded-[50%]">
                    </h1>
                </div>
            </div>
            <section v-else class="w-screen h-screen relative" >
            <div class="w-full h-full flex absolute">
                <div class="w-6/12 h-full bg-blue-200/70"></div>
                <div class="w-6/12 h-full bg-blue-900"></div>
            </div>
            <div class="container w-full h-full absolute z-10 overflow-hidden md:bg-white bg-gray-100 top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] flex md:flex-row flex-col-reverse max-w-[900px] md:max-h-[500px] md:rounded-[20px] shadow-4xl">
                <div class="md:w-6/12 w-full h-full bg-gray-100 md:p-[50px] p-[20px] flex flex-col justify-center md:rounded-none relative md:top-0 top-[-50px] rounded-tl-[50px]">
                    <h1 class="text-3xl font-bold my-[15px] font-serif mb-[50px] text-black text-center">Login</h1>
                    <form  @submit.prevent="login" class="w-full relative flex flex-col">
                        <div class="w-full my-[10px] flex flex-col items-start relative">
                            <label for="username" class="text-sm font-bold text-gray-700 absolute top-[10px] left-[10px]">Username : </label>
                            <input type="text" v-model="username"  id="username" placeholder="nom.prenom@site.com" required class="w-full outline-none p-[10px] pt-[35px] rounded-[5px] shadow-5xl bg-white text-sm font-semibold">
                        </div>
                        <div class="w-full my-[10px] flex flex-col items-start relative">
                            <label for="password" class="text-sm font-bold text-gray-700 absolute top-[10px] left-[10px]">Mot de passe : </label>
                            <input type="password" v-model="password"  placeholder="Mot de passe" id="password" required class="w-full outline-none p-[10px] pt-[35px] rounded-[5px] shadow-5xl bg-white text-sm font-semibold">
                        </div>
                        <div class="w-full my-[10px] flex items-center justify-start relative">
                            <input type="checkbox" v-model="keepSession" id="session">
                            <label for="session" class="text-sm font-bold text-gray-700 mx-[10px]">Garder ma session ouverte</label>
                        </div>
                        <div class="w-full my-[10px] mt-[25px] flex flex-col items-start relative">
                            <button type="submit" :disabled="loading" class="w-full cursor-pointer outline-none p-[15px] text-center flex justify-center items-center rounded-[10px] shadow-5xl bg-gray-800 text-white font-bold text-sm">
                                <div v-if="loading" >
                                    <div class="newtons-cradle">
                                        <div class="newtons-cradle__dot"></div>
                                        <div class="newtons-cradle__dot"></div>
                                        <div class="newtons-cradle__dot"></div>
                                        <div class="newtons-cradle__dot"></div>
                                    </div>
                                </div>
                                <span v-else>Se connecter</span>
                            </button>
                        </div>
                    </form>
                </div>
                <div class="md:w-6/12 w-full md:h-full md:bg-blue-200/70 bg-gray-800 flex justify-center items-center min-h-[180px] z-[-1]">
                    <img src="../assets/images/Logo.jpg" alt="" class="w-[75px] h-[75px] rounded-[50%] relative top-[-25px] md:hidden">
                </div>
            </div>
        </section>
        </transition>
        
    </div>
</template>
  
<script setup>
    import { ref, onMounted } from 'vue';
    import axios from 'axios';
    import { useRouter } from 'vue-router';
    const loading = ref(false);

    const router = useRouter();

    const showIntro = ref(true)
    onMounted(() => {
        setTimeout(() => {
            showIntro.value = false;
        }, 2000);


        const isAuthenticated = () => {
            const token = localStorage.getItem('token');
            return !!token;
        };
        if (isAuthenticated) {
            router.push('/resum')
        }
    });

    const username = ref('');
    const password = ref('');
    const errorMessage = ref('');

    const keepSession = ref(false);

async function login() {
    try {
        loading.value = true;
        const response = await axios.post('/login', { username: username.value, password: password.value });
        const token = response.data;
        localStorage.setItem('token', token);
        router.push('/resum');
    } catch (error) {
        if (error.response.status === 404 || error.response.status === 400 || error.response.status === 403 || error.response.status === 401) {
            errorMessage.value = 'Mot de passe incorrect ou utilisateur introuvable.';
            hideErrorMessageAfterDelay();
        } else {
            console.error('Erreur de connexion :', error);
        }
    } finally {
        loading.value = false; 
    }
}

const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
        errorMessage.value = ''; 
    }, 2500);
};
</script>

  
<style scoped>
    .intro-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(31 41 55 );
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        animation: slide-out 2s forwards;
    }
  
    @keyframes slide-out {
        0% {
            transform: scale(1);
        }
        25% {
            transform: scale(1);
        }
        100% {
            transform: scale(2);
        }
    }
  
    .intro-title {
        animation: spin 2s normal 1;
    }
  
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
  
    .main-content {
        display: none;
    }

.newtons-cradle {
 --uib-size: 20px;
 --uib-speed: 1.2s;
 --uib-color: #fff;
 position: relative;
 display: flex;
 align-items: center;
 justify-content: center;
 width: 80px;
 height: var(--uib-size);
}

.newtons-cradle__dot {
 position: relative;
 display: flex;
 align-items: center;
 height: 10px;
 width: 10px;
 transform-origin: center top;
}

.newtons-cradle__dot::after {
 content: '';
 display: block;
 width: 10px;
 height: 10px;
 border-radius: 50%;
 background-color: var(--uib-color);
}

.newtons-cradle__dot:first-child {
 animation: swing var(--uib-speed) linear infinite;
}

.newtons-cradle__dot:last-child {
 animation: swing2 var(--uib-speed) linear infinite;
}

@keyframes swing {
 0% {
  transform: rotate(0deg);
  animation-timing-function: ease-out;
 }

 25% {
  transform: rotate(70deg);
  animation-timing-function: ease-in;
 }

 50% {
  transform: rotate(0deg);
  animation-timing-function: linear;
 }
}

@keyframes swing2 {
 0% {
  transform: rotate(0deg);
  animation-timing-function: linear;
 }

 50% {
  transform: rotate(0deg);
  animation-timing-function: ease-out;
 }

 75% {
  transform: rotate(-70deg);
  animation-timing-function: ease-in;
 }
}

</style>
  