<template>
    <section class="flex relative justify-center bg-gray-100 w-screen min-h-screen h-auto pb-[50px]">
        <div class="container px-[20px] max-w-[500px] flex flex-col justify-start items-center">
            <div class="my-[25px] w-full flex justify-between items-center">
                <i @click="logout()" class="fa-solid fa-right-from-bracket text-2xl"></i>
                <h1 class="text-center font-semibold">Tableau de bord</h1>
                <div>
                    <button class="relative">
                        <i class="fa-solid fa-bell text-2xl mr-[5px]"></i>
                        <div class="absolute hidden top-[-5px] right-[-5px] bg-red-800 w-[15px] h-[15px] rounded-[50%] justify-center items-center text-xs font-bold text-gray-100 animate-pulse">

                        </div>
                    </button>
                    <i class="fa-solid fa-envelope text-2xl ml-[35px] cursor-pointer" @click="goToMessagePage()"></i>
                </div>
            </div>
            <div class="w-[90%] my-[25px] bg-gray-300 flex justify-center items-center rounded-[50px]">
                <div @click="toggleCommande()" class="w-6/12 duration-500 cursor-pointer text-center text-xs flex items-center justify-center p-[10px] rounded-[50px]" :class="{'bg-white font-bold': isCommande}">
                    <i class="fa-solid fa-cart-shopping mx-[5px]"></i>
                    Commande
                </div>
                <div @click="toggleReservation()" class="w-6/12 duration-500 cursor-pointer text-center text-xs flex items-center justify-center text-gray-600 p-[10px] rounded-[50px]" :class="{'bg-white font-bold': isReservation}">
                    <i class="fa-solid fa-book mx-[5px]"></i>
                    Réservation
                </div>
            </div>
            <div v-if="isCommande" class="w-full duration-500">
                <router-link :to="'/commande-detail/' + commande._id" v-for="(commande, index) in commandesFromServer" :key="index" class="my-[10px] cursor-pointer rounded-[10px] w-full p-[10px] flex justify-between items-center bg-white">
                    <div class="pr-[10px] h-full relative">
                        <h1 class="bg-orange-500 text-xs font-bold text-center h-full text-gray-50 flex justify-center items-center p-[5px] rounded-[5px]">{{ commande.idCommande }}</h1>
                    </div>
                    <div id="creu" class="w-full relative flex flex-col justify-center border-l border-gray-400 border-dashed pl-[10px] px-[15px] items-start">
                        <h1 class="text-sm font-bold text-black">{{ commande.personData[0].nomPrenom }}</h1>
                        <span class="text-xs text-gray-700">{{ commande.personData[0].adresse }}</span>
                    </div>
                    <div class="text-sm text-gray-700 font-bold flex flex-col items-end justify-center">
                        {{ formatMontant(commande.selectedMenus) }} <span>FCFA</span>
                    </div>
                </router-link>
            </div>
            <div v-if="isReservation" class="w-full duration-500">
                <div v-for="(reservation, index) in reservations" :key="index" class="my-[10px] cursor-pointer rounded-[10px] w-full p-[10px] flex justify-between items-center bg-white">
                    <div class="pr-[10px] h-full relative">
                        <h1 class="bg-blue-500 text-xs font-bold text-center h-full text-gray-50 flex justify-center items-center p-[5px] rounded-[5px]">{{ reservation.idReservation }}</h1>
                    </div>
                    <div id="creu" class="w-full relative flex flex-col justify-center border-l border-gray-400 border-dashed pl-[10px] px-[15px] items-start">
                        <h1 class="text-sm font-bold text-black">{{ reservation.nom }}</h1>
                        <span class="text-xs text-gray-700">{{ reservation.date }} - {{ reservation.heure }}</span>
                    </div>
                    <div class="text-sm flex-col justify-center items-center text-gray-700 font-bold flex">
                        {{ reservation.place }} 
                        <h1>
                            Personne
                        </h1>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>
  
<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router';

const router = useRouter();

function goToMessagePage() {
    router.push("/message")
}

const reservations = ref([]);

async function logout() {
    try {
        localStorage.removeItem('token');
        router.push('/');
    } catch (error) {
        console.error('Erreur lors de la déconnexion :', error);
    }
}


const isReservation = ref(false);
const isCommande = ref(true);

function toggleReservation() {
    isReservation.value = true
    isCommande.value = false
}
function toggleCommande() {
    isReservation.value = false
    isCommande.value = true
}

const commandesFromServer = ref([]);

const fetchCommandesFromServer = async () => {
    try {
        const response = await axios.get('/commandes/');
        commandesFromServer.value = response.data;
    } catch (error) {
        console.error('Erreur lors de la récupération des commandes:', error);
    }
};

const fetchReservationsFromServer = async () => {
    try {
        const response = await axios.get('/reservations');
        reservations.value = response.data;
    } catch (error) {
        console.error('Erreur lors de la récupération des réservations:', error);
    }
};

onMounted(() => {
    fetchCommandesFromServer();
    fetchReservationsFromServer();
});

const formatMontant = (selectedMenus) => {
    let total = 0;
    selectedMenus.forEach(menu => {
        total += menu.units * menu.unitPrice;
    });
    return total;
};
</script>
  
<style scoped>
#creu::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(243 244 246 );
    position: absolute;
    top: -17px;
    left: -5px;
}
#creu::after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(243 244 246 );
    position: absolute;
    bottom: -17px;
    left: -5px;
}
</style>
  