<template>
    <section  class="w-screen bg-gray-400 h-screen flex justify-center min-h-[500px] relative">
        <img src="../assets/images/fond.png" alt="" class="w-full h-full md:flex z-0 absolute object-cover opacity-10">
        <div id="parent" class="container px-[20px] bg-gray-200 pb-[50px] h-screen overflow-y-auto z-10 max-w-[500px] flex flex-col justify-start items-center">
            <div class="my-[25px] w-full flex justify-between items-center">
                <i @click="goBack()" class="fa-solid fa-chevron-left text-2xl"></i>
                <h1 class="text-center font-semibold">Tableau de bord</h1>
                <button class="relative">
                    <i class="fa-solid fa-bell text-2xl"></i>
                    <div class="absolute top-[-5px] right-[-5px] bg-red-800 w-[15px] h-[15px] rounded-[50%] hidden justify-center items-center text-xs font-bold text-gray-100 animate-pulse">
                        
                    </div>
                </button>
            </div>
            <div class="w-[90%] my-[25px] bg-gray-300 flex justify-center items-center rounded-[50px]">
                <div class="w-full duration-500 cursor-pointer text-center text-xs flex items-center justify-center p-[10px] rounded-[50px] bg-white font-bold">
                    <i class="fa-solid fa-envelope mx-[5px]"></i>
                    Message
                </div>
            </div>
            <div class="w-full duration-500">
                <div v-for="(message, index) in messages" :key="index" class="my-[10px] mb-[20px] cursor-pointer rounded-[10px] w-full p-[10px] flex justify-between items-start bg-white">
                    <div class="pr-[10px] h-full flex justify-center items-start relative">
                        <div class="w-[40px] h-[40px] border-2 border-blue-400 rounded-[50%] flex justify-center items-center">
                            <i class="fa-solid fa-envelope text-blue-400 text-2xl"></i>
                        </div>
                    </div>
                    <div class="w-full relative flex flex-col justify-center border-l border-gray-400 border-dashed pl-[10px] px-[15px] items-start">
                        <h1 class="text-sm font-bold text-black">{{ message.nomPrenom }}</h1>
                        <span class="text-xs font-bold text-gray-700 mb-[5px]">{{ message.emailNum }}</span>
                        <span class="text-xs text-gray-700">{{ message.message }}</span>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

const router = useRouter();
const messages = ref(null);

function goBack() {
    router.push('/resum');
}

onMounted(async () => {
    try {
        const response = await axios.get(`/messages`);
        messages.value = response.data;
    } catch (error) {
        console.error('Erreur lors de la récupération des détails des messages :', error);
    }
});
</script>



<style>

</style>