import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import authGuard from './authGuard';

import HomeView from '../views/LoginView.vue'
import ResumView from '@/views/ResumView.vue'
import CommandeView from '@/views/CommandeView.vue'
import DirectionsView from '@/views/DirectionsView.vue'
import MapView from '@/views/MapView.vue'
import MessageView from '@/views/MessageView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: false },
  },
  {
    path: '/resum',
    name: 'Resum',
    component: ResumView,
    meta: { scrollToTop: true, requiresAuth: true },
  },
  {
    path: '/commande-detail/:id',
    name: 'CommandeDetail',
    component: CommandeView,
    meta: { scrollToTop: true, requiresAuth: true },
  },
  {
    path: '/direction',
    name: 'DirectionsView',
    component: DirectionsView,
    meta: { scrollToTop: true, requiresAuth: true },
  },
  {
    path: '/map',
    name: 'map',
    component: MapView,
    meta: { scrollToTop: true, requiresAuth: true },
  },
  {
    path: '/message',
    name: 'message',
    component: MessageView,
    meta: { scrollToTop: true, requiresAuth: true },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(authGuard);

export default router
