import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import "./assets/style.css"

import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_API_URL

import { createPinia } from 'pinia'

const pinia = createPinia()

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js')
    .then((reg) => {
        console.log('Service worker registered.', reg);
    }).catch((err) => {
        console.log('Service worker registration failed:', err);
    });
}
  
const app = createApp(App)

app.use(store)
app.use(router)
app.use(pinia)
app.mount('#app')
