<template>
    <section  v-if="commande" class="w-screen bg-gray-400 h-screen flex justify-center min-h-[500px] relative">
        <img src="../assets/images/fond.png" alt="" class="w-full h-full md:flex z-0 absolute object-cover opacity-10">
        <div class="container w-full h-full max-w-[500px]">
            <div class="h-3/6 w-full bg-gray-800 relative flex justify-center items-center">
                <div @click="goBack()" class="bg-white text-black w-[30px] h-[30px] flex justify-center cursor-pointer z-50 items-center text-center rounded-[50%] absolute top-[20px] left-[20px]">
                    <i class="fa-solid fa-chevron-left"></i>
                </div>
                <img src="../assets/images/Caille/Avis1.png" alt="" class="w-[80%] z-[1] relative top-[-40px]">
                <div class="absolute bottom-[30px] p-[20px] z-10 w-[90%] flex justify-between items-center">
                    <div class="flex flex-col justify-center items-center">
                        <h1 class="text-2xl font-bold text-white">{{ commande ? commande.idCommande : '' }}</h1>
                        <span class="text-xs text-gray-400">Identifiant</span>
                    </div>
                    <div class="flex flex-col justify-center items-center">
                        <h1 class="text-2xl font-bold text-white">{{ commande ? commande.selectedMenus.length : '' }}</h1>
                        <span class="text-xs text-gray-400 capitalize">éléments</span>
                    </div>
                    <div class="flex flex-col justify-center items-center">
                        <h1 class="text-2xl font-bold text-white">{{ formatMontant(commande.selectedMenus) }}</h1>
                        <span class="text-xs text-gray-400">Montant</span>
                    </div>
                </div>
            </div>
            <div id="parent" class="h-[calc(50%+30px)] w-full z-10 overflow-y-auto rounded-t-[30px] bg-white relative top-[-30px] p-[30px]">
                <div class="flex justify-between items-center">
                    <div>
                        <h1 class="text-gray-700 font-bold">{{ commande ? commande.personData[0].nomPrenom : '' }}</h1>
                        <h1 class="text-xs text-gray-700">{{ commande ? commande.personData[0].email : '' }}</h1>
                    </div>
                    <div @click="goDirection()" class="p-[10px] z-20 cursor-pointer rounded-[30px] border-2 border-gray-500 text-sm font-bold text-gray-500">Get location</div>
                </div>
                <div class="my-[25px]">
                    <h1 class="text-gray-700 font-bold mb-[10px]">Photos</h1>
                    <div id="parent" class="w-full overflow-x-auto flex py-[5px]">
                        <div v-for="(menu, index) in commande ? commande.selectedMenus : []" :key="index" class="min-w-[75px] min-h-[75px] w-[75px] h-[75px] shadow-6xl rounded-[10px] overflow-hidden mr-[10px] object-cover relative flex justify-center items-center">
                            <img :src="menu.image" :alt="menu.name" class="w-full h-full absolute object-cover">
                        </div>
                    </div>
                </div>
                <div class="my-[25px]">
                    <h1 class="text-gray-700 font-bold mb-[10px] capitalize">à propos</h1>
                    <div class="w-full text-sm text-gray-500">
                        <span v-for="(menu, index) in commande ? commande.selectedMenus : []" :key="index" class="mx-[5px]">{{ menu.units }} {{ menu.name }}</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';

const route = useRoute();
const router = useRouter();
const commande = ref(null);

function goBack() {
    router.push('/resum');
}

function goDirection() {
    router.push('/direction');
}

onMounted(async () => {
    const commandeId = route.params.id;

    try {
        const response = await axios.get(`/commandes/${commandeId}`);
        commande.value = response.data;
    } catch (error) {
        console.error('Erreur lors de la récupération des détails de la commande :', error);
    }
});

// Fonction pour formater le montant total de la commande
const formatMontant = (selectedMenus) => {
    let total = 0;
    selectedMenus.forEach(menu => {
        total += menu.units * menu.unitPrice;
    });
    return total;
};
</script>

<style>

</style>